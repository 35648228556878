module.exports = [{
      plugin: require('/zeit/5bd6f83e/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"marina-pet-sitter","short_name":"marina","start_url":"/","background_color":"#005560","theme_color":"#005560","display":"minimal-ui","icon":"src/images/fav.png"},
    },{
      plugin: require('/zeit/5bd6f83e/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/typography","omitGoogleFont":true},
    },{
      plugin: require('/zeit/5bd6f83e/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
